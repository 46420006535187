import FileTabs from "./FileTabs";

function Tabs() {
    return (
        <div id="tabs" className="mt-8">
            <h2 className="text-4xl">Tabs</h2>

            <FileTabs />
        </div>
    )
}

export default Tabs;

import Item from "./Item";

function SideBar() {
    return (
        <div className="p-2">
            <ul className="mt-4 space-y-2">
                <Item name="Tabs" link="tabs" />
                <Item name="File Tabs" link="tabs" level={2} />
            </ul>
        </div>
    )
}

export default SideBar;
import './app.css';
import './styles.css';
import Tabs from "./components/sections/Tabs";
import React, {useState} from "react";
import SideBar from "./components/side-bar/SideBar";
import Header from "./components/header/Header";
import classNames from "classnames";

function App() {
    const [currentFont, setCurrentFont] = useState('sans-serif');

    const fontHandler = () => {
        setCurrentFont(prevFont =>
            prevFont === 'sleeping-elephant' ? 'sans-serif' : 'sleeping-elephant'
        );
    };

    const classes = classNames(
        'grid md:grid-cols-[1fr_6fr] gap-8',
        {
            'font-se': currentFont === 'sleeping-elephant',
            'font-default': currentFont === 'sans-serif',
        }
    );

    return (
        <div>
            <Header
                fontHandler={fontHandler}
                switchTo={currentFont === 'sleeping-elephant' ? 'Sans-Serif' : 'Sleeping Elephant'}
            />

            <div className={classes}>
                <SideBar/>

                <div className="p-5">
                    <h1>Sleeping Elephant</h1>

                    <Tabs/>
                </div>
            </div>
        </div>
    );
}

export default App;
